.carousel img {
  object-fit: cover;
  width: 100%; 
  height: 400px; 

}

@media (min-width: 768px) {
  .carousel img {
    height: 500px; 
  }
}

@media (min-width: 1024px) {
  .carousel img {
    height: 600px; 
  
  }
}

